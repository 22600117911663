import React, { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom"; // ✅ Correct import for React Router v5

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(Cookies.get("mitra_token") || null);
  const history = useHistory(); // ✅ Use useHistory() instead of useNavigate()

  // Function to log out user
  const logoutUser = () => {
    Cookies.remove("mitra_token");
    Cookies.remove("mitraRefreshToken");
    setToken(null);
    history.push("/login"); // ✅ Redirect to login page
  };

  // Function to refresh the token
  const refreshAccessToken = async () => {
    console.log("refresh method calls")
    try {
      const refreshToken = Cookies.get("refreshToken");
      if (!refreshToken) {
        console.warn("No refresh token available");
        logoutUser(); // 🔥 Force logout if no refresh token
        return;
      }

      const deviceId = localStorage.getItem("device_id")

      const url = `${process.env.REACT_APP_BASE_URL}/shop/refreshToken`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken, deviceId }),   
        credentials: "include",
      });

      const data = await response.json();
      if (data.accessToken) {
        setToken(data.accessToken);
        Cookies.set("mitra_token", data.accessToken, {
          secure: true,
          sameSite: "Lax",
        });
        console.log("Token refreshed successfully");
      } else {
        console.warn("Failed to refresh token");
        logoutUser(); // 🔄 Logout on failure
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      logoutUser(); // 🔄 Logout on error
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Refreshing token...");
      refreshAccessToken();
    }, 50 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return <AuthContext.Provider value={{ token }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
