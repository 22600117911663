import { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { TailSpin } from 'react-loader-spinner'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import  AuthProvider  from './context/authContext';
import { pdfjs } from 'react-pdf';

// Lazy load components
const LandingPage = lazy(() => import('./components/LandingPage'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const RegisterPage = lazy(() => import('./components/RegisterPage'));
const Home = lazy(() => import('./components/Home'));
const HistoryPage = lazy(() => import('./components/HistoryPage'));
const EmailVerification = lazy(() => import('./components/EmailVerification'));
const UpdatePassword = lazy(() => import('./components/UpdatePassword'));
const ProfilePage = lazy(() => import('./components/ProfilePage'));
const TransactionHistory = lazy(() => import('./components/TransactionHistory'));
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute'));
const UploadImagesPage = lazy(() => import('./components/UploadImagesPage'));
const MakeOrder = lazy(() => import('./components/MakeOrder'));

const LoadingView = () => (
  <div className="loading-section" data-testid="loader">
    <TailSpin color="#0b69ff" height={80} width={80} />
  </div>
)

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`


const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <Suspense fallback={<LoadingView />}>
              <Switch>
                <Route exact path='/' component={LandingPage} />
                <Route exact path='/login' component={LoginPage} />
                <Route exact path='/verify' component={EmailVerification} />
                <Route exact path='/register' component={RegisterPage} />
                <Route exact path='/updatepassword' component={UpdatePassword} />
                <Route exact path='/profile' component={ProfilePage} />
                <Route exact path='/home' component={Home} />
                <Route exact path='/uploadimages' component={UploadImagesPage} />
                <Route exact path='/makeorder' component={MakeOrder} />
                <Route exact path='/transactionhistory' component={TransactionHistory} />
                <Route exact path='/history' component={HistoryPage} />
              </Switch>
            </Suspense>
          </BrowserRouter>
      </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
